
.songkick-container .widget_row {
	display: block;
	position: relative;
	padding: 0px 0px 30px 0px;
	border: none;
	font-family: "eurostile";
	font-size: 12px;
	text-align: left;
	text-transform: uppercase;
	letter-spacing: 1px;
}
.text_ep {
  display: block;
  float: left;
  text-align: left;
  margin-left: 10px;
  color: #ffffff;
  text-transform: uppercase;
  clear: right; 
  padding-bottom: 10px;
}

.text_ep p{
  font-family: 'Abel', sans-serif;
  margin-bottom: 0px;
  font-size: 14px;
}

.text_download {
  display: block;
  float: left;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  clear: both; 
  padding-bottom: 0px;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  margin-top: 20px;
}

.text_download a{
  display: block;
  float: left;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  clear: left;
}
.sectionEvents {
  background-color: #000;
  height: 100%; 
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px;
  padding: 0px;
  height: 900px;
}

.listEvents h2{
  color: red;  
  display: block;
}

div.darkTable {
  font-family: 'Open Sans Condensed', sans-serif;
  border: 0px solid #000000;
  background-color: #000000;
  width: 60%;
  height: 200px;
  text-align: left;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
}
.divTableCell, .divTable.darkTable .divTableHead {
  border: 0px solid #4A4A4A;
  padding: 10px 2px;
}
.divTable.darkTable .divTableBody .divTableCell {
  font-size: 15px;
  color: #ffffff;
}
.divTable{ display: table; }
.divTableRow { display: table-row; }
.divTableHeading { display: table-header-group;}
.divTableCell, .divTableHead { display: table-cell;}
.divTableHeading { display: table-header-group;}
.divTableFoot { display: table-footer-group;}
.divTableBody { display: table-row-group;}
